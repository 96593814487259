import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { Product } from '../classes/product'
import { StoreService } from './store.service'
import { getCountry } from 'src/main'

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public cart
  public state
  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart = false
  public Products
  private readonly storeBaseUrl: string
  private productUrl: string
  private productFilterUrl: string
  private readonly baseImgUrl: string
  public filter

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private storeService: StoreService,
    @Inject('BASE_URL') baseUrl: string,
    @Inject('BASE_IMG_URL') baseImgUrl: string
  ) {
    this.baseImgUrl = baseImgUrl
    this.cart = sessionStorage.getItem(localStorage.storeUuid)
    this.state = {
      products: JSON.parse(localStorage.products || '[]'),
      wishlist: JSON.parse(localStorage.wishlistItems || '[]'),
      compare: JSON.parse(localStorage.compareItems || '[]'),
      cart: JSON.parse(this.cart || '[]'),
    }
    this.storeBaseUrl = `${baseUrl}/microsite/by-uri`
    this.productUrl = `${baseUrl}/products_api/products/:sku`
    this.productFilterUrl = `${baseUrl}/products_api/products?page=1&size=1000&country=:country&skus=:skus&priceOrder=:priceOrder&name=:name`
  }

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(this.state.wishlist)
      observer.complete()
    })
    return itemsStream as Observable<Product[]>
  }

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(this.state.compare)
      observer.complete()
    })
    return itemsStream as Observable<Product[]>
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      if (!this.state.cart) {
        this.state.cart = []
      }

      observer.next(this.state.cart)
      observer.complete()
    })

    return itemsStream as Observable<Product[]>
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return of([])
    // return this.products;
  }

  // Product
  // private get products(): Observable<Product[]> {
  //   this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
  //   this.Products.subscribe(next => {
  //     localStorage.products = JSON.stringify(next);
  //   });
  //   return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage.products || '[]')));
  // }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  /*
    ---------------------------------------------
    ---------------  Landing  -------------------
    ---------------------------------------------
  */

  getLandingProduct(sku: string): Observable<Product> {
    return this.http
      .get<Product>(this.productUrl.replace(':sku', sku), {
        headers: { storeCountry: getCountry() },
      })
      .pipe(
        map((product) => {
          product.images = product.images.map((imgPath) => {
            const formattedUrl = this.baseImgUrl.replace(':imgPath', imgPath)
            return `${formattedUrl}`
          })
          return product
        })
      )
  }

  getEarningsValue(price: number): number {
    return (Number(this.storeService.getPercentage()) / 100) * price + price
  }

  // Get Products By Slug
  // public getProductBySlug(slug: string): Observable<Product> {
  //   return this.products.pipe(map(items => {
  //     return items.find((item: any) => {
  //       return item.title.replace(' ', '-') === slug;
  //     });
  //   }));
  // }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = this.state.wishlist.find((item) => item.id === product.id)
    if (!wishlistItem) {
      this.state.wishlist.push({
        ...product,
      })
    }
    this.toastrService.success('Product has been added in wishlist.')
    localStorage.setItem('wishlistItems', JSON.stringify(this.state.wishlist))
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = this.state.wishlist.indexOf(product)
    this.state.wishlist.splice(index, 1)
    localStorage.setItem('wishlistItems', JSON.stringify(this.state.wishlist))
    return true
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = this.state.compare.find((item) => item.id === product.id)
    if (!compareItem) {
      this.state.compare.push({
        ...product,
      })
    }
    this.toastrService.success('Product has been added in compare.')
    localStorage.setItem('compareItems', JSON.stringify(this.state.compare))
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = this.state.compare.indexOf(product)
    this.state.compare.splice(index, 1)
    localStorage.setItem('compareItems', JSON.stringify(this.state.compare))
    return true
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = this.state.cart.find((item) => item.sku === product.sku)
    const qty = product.quantity ? product.quantity : 1
    const items = cartItem ? cartItem : product
    const stock = this.calculateStockCounts(items, qty)

    if (!stock) {
      return false
    }

    if (cartItem) {
      cartItem.quantity += qty
    } else {
      this.state.cart.push({
        ...product,
        quantity: qty,
      })
    }

    this.OpenCart = true // If we use cart variation modal
    sessionStorage.setItem(localStorage.storeUuid, JSON.stringify(this.state.cart))
    return true
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return this.state.cart.find((items, index) => {
      if (items.sku === product.sku) {
        const qty = this.state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(this.state.cart[index], quantity)
        if (qty !== 0 && stock) {
          this.state.cart[index].quantity = qty
        }
        sessionStorage.setItem(localStorage.storeUuid, JSON.stringify(this.state.cart))
        return true
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock === 0) {
      this.toastrService.error(
        'You can not add more items than available. In stock ' + stock + ' items.'
      )
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = this.state.cart.indexOf(product)
    this.state.cart.splice(index, 1)
    sessionStorage.setItem(localStorage.storeUuid, JSON.stringify(this.state.cart))
    return true
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price =
            (Number(this.storeService.getPercentage()) / 100) * curr.pricePesos + curr.pricePesos

          if (curr.discount) {
            // price = price - ((percentVendor / 100 * curr.pricePesos) * curr.discount / 100);
            price = price - (price * curr.discount) / 100
          }
          return parseFloat(((prev + price * curr.quantity) * this.Currency.price).toFixed(2))
        }, 0)
      })
    )
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return of([])
    // return this.products.pipe(map(product =>
    //   product.filter((item: Product) => {
    //     if (!filter.length) {
    //       return true;
    //     }
    //     const Tags = filter.some((prev) => { // Match Tags
    //       if (item.tags) {
    //         if (item.tags.includes(prev)) {
    //           return prev;
    //         }
    //       }
    //     });
    //     return Tags;
    //   })
    // ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1
        } else if (a.id > b.id) {
          return 1
        }
        return 0
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.artNombreMl < b.artNombreMl) {
          return -1
        } else if (a.artNombreMl > b.artNombreMl) {
          return 1
        }
        return 0
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.artNombreMl > b.artNombreMl) {
          return -1
        } else if (a.artNombreMl < b.artNombreMl) {
          return 1
        }
        return 0
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.pricePesos < b.pricePesos) {
          return -1
        } else if (a.pricePesos > b.pricePesos) {
          return 1
        }
        return 0
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.pricePesos > b.pricePesos) {
          return -1
        } else if (a.pricePesos < b.pricePesos) {
          return 1
        }
        return 0
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize)

    // Paginate Range
    const paginateRange = 3

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1
    } else if (currentPage > totalPages) {
      currentPage = totalPages
    }

    let startPage: number
    let endPage: number
    if (totalPages <= 5) {
      startPage = 1
      endPage = totalPages
    } else if (currentPage < paginateRange - 1) {
      startPage = 1
      endPage = startPage + paginateRange - 1
    } else {
      startPage = currentPage - 1
      endPage = currentPage + 1
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i)

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    }
  }

  public cleanCart(): void {
    this.state.cart.splice(0, this.state.cart.length)
    localStorage.removeItem('cartItems')
  }

  public productsFilter(): Observable<{ products: Product[]; total: number }> {
    const url = this.productFilterUrl
      .replace(':country', getCountry())
      .replace(':skus', this.storeService.store.catalog.skuProducts.toString())
      .replace(':priceOrder', this.filter?.priceOrder)
      .replace(':name', this.filter?.name)
    if (this.filter.categoryId === '') {
      return this.http.get<{ products: Product[]; total: number }>(url).pipe(
        map((response) => {
          response.products.map((product) => {
            product.images = product.images.map((imgPath) => {
              const formattedUrl = this.baseImgUrl.replace(':imgPath', imgPath)
              return `${formattedUrl}`
            })
          })
          return response
        })
      )
    }
    return this.http
      .get<{ products: Product[]; total: number }>(
        `${url}&categoryId=${this.filter.categoryId.toString()}`
      )
      .pipe(
        map((response) => {
          response.products.map((product) => {
            product.images = product.images.map((imgPath) => {
              const formattedUrl = this.baseImgUrl.replace(':imgPath', imgPath)
              return `${formattedUrl}`
            })
          })
          return response
        })
      )
  }
}
