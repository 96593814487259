import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Product } from '../classes/product'
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categories = []
  productUrl = ''
  categoriesByStore = []

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.productUrl = `${baseUrl}/products_api/categories`
  }

  getCategories(): Observable<any> {
    return this.http.get(this.productUrl);
  }

  getCategoryByProducts(product: Product) {
    product.categories.map((category) => {
      this.categories
        .filter((cat) => cat.id === category)
        .map((category) => {
          if (!this.categoriesByStore.some((cate) => cate.id.toString() === category.id.toString()))
            this.categoriesByStore.push(category)
        })
    })
    return this.categoriesByStore
  }
}
