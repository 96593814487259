<!-- section start -->
<section class="section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6" style="margin-bottom: 5px">
        <div class="dashboard-right">
          <div class="dashboard">
            <div class="page-title">
              <h2>{{ 'Landing.title' | translate }}</h2>
            </div>
            <div class="welcome-msg">
              <p>{{ 'Landing.subtitle' | translate }}</p>
            </div>
            <br />
            <div class="box-account box-info">
              <div class="box-head">
                <img
                  src="assets/images/BeeSellerLogo.png"
                  alt="BeeSeller Logo"
                  style="max-height: 200px"
                />
                <h3>{{ 'Landing.enjoy' | translate }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dashboard-left" *ngIf="stores.length > 0">
          <div class="block-content">
            <ul *ngFor="let store of stores">
              <li>
                <a href="{{ store.uri }}"
                  >{{ store.vendorCountry | uppercase }} - {{ store.uri }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer
  [contactData]="contactDataBeeSeller"
  [contactStg]="contactStg"
  [fullContact]="true"
></app-footer>
<!-- section end -->
