import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ElectronicsComponent} from './electronics/electronics.component';
import {StoreResolver} from '../shared/services/store.resolver';

const routes: Routes = [
  {
    path: 'electronics/:storeUri',
    component: ElectronicsComponent,
    resolve: {
      data: StoreResolver
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
