import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {getStoreUriFromCurrentUrl} from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ShopResolver implements Resolve<{ storeUri: string }> {

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<{ storeUri: string }> {
    return {storeUri: getStoreUriFromCurrentUrl(state.url)};
  }
}
