import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Menu, NavService } from '../../services/nav.service'
import { Router } from '@angular/router'
import { ProductService } from '../../services/product.service'

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  public menuItems: Menu[]
  @Input() storeName: string
  @Input() storeColor: string
  @Output() onSearch: EventEmitter<number> = new EventEmitter<number>()

  constructor(
    private router: Router,
    public navServices: NavService,
    private productService: ProductService
  ) {
    this.navServices.leftMenuItems$.subscribe((menuItems) => {
      this.menuItems = menuItems.map((item) => {
        return { title: item.name, type: 'button', id: item.id }
      })
      this.menuItems.unshift({ title: 'Todos', type: 'button', id: 0 })
    })
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false
    })
  }

  ngOnInit(): void {
    this.navServices.leftMenuToggle = false
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  onSearchCategory(id: number) {
    this.onSearch.emit(id)
    this.leftMenuToggle()
  }
}
