<header
  [class.fixed]="stick && sticky"
  [ngClass]="class"
  class="fixed-top"
  [style]="{ 'background-color': storeColor }"
>
  <div class="container d-flex flex-column gap5">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div *ngIf="widthMobile" class="categories">
              <app-left-menu
                (onSearch)="searchCategoryByMobile($event)"
                [storeName]="storeName"
                [storeColor]="storeColor"
              ></app-left-menu>
            </div>
            <div class="text-start">
              <a routerLink="/{{ storeUri }}">
                <strong class="text-uppercase store-name" [style]="'color:' + storeNameColor + ';'">
                  {{ storeName }}
                </strong>
              </a>
            </div>
          </div>
          <div class="menu-right pull-right flex-row align-items-center height45">
            <div class="search inner-addon left-addon">
              <i class="fa fa-search"></i>
              <input
                type="search"
                class="form-control height40"
                [placeholder]="'Filter.search' | translate"
                (input)="onInput($event)"
              />
            </div>
            <div class="paddingtop12">
              <app-settings [storeUri]="storeUri"></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!widthMobile">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="text-start d-flex gap5">
              <button type="button" class="btn btn-light rounded" (click)="searchCategory('')">
                Todos
              </button>
              <button
                *ngFor="let category of categories"
                type="button"
                class="btn btn-light rounded"
                (click)="searchCategory($event.target.value)"
                [value]="category.id"
              >
                {{ category.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
