<!--footer section -->
<footer
        class="footer-container"
        style="background: url('../../../../assets/images/FooterPng3.png'), #494c4e"
>
    <br/><br/><br/>
    <!-- <div class=""> -->
    <div class="bg-white" *ngIf="!fullContact && country === 'UY'">
        <!--	<app-payments-methods [ab]="true" [rp]="true" [vi]="true" [mc]="true" [oca]="true" [ld]="true"
         [dn]="true"></app-payments-methods> -->
        <img src="assets/images/medios_pago.png" class="w-100 medios-pagos-img"/>
    </div>
    <!-- </div> -->

    <section>
        <div
                class="footer-contact"
                *ngIf="contactData.facebook || contactData.tiktok || contactData.instagram"
        >
            <strong>{{ 'Footer.contact' | translate }}</strong>
            <ng-container *ngIf="fullContact; else elseTemplate">
                <app-contact-banner
                        [type]="'all'"
                        [contacts]="contactData"
                        [settings]="contactStg"
                ></app-contact-banner>
            </ng-container>
            <ng-template #elseTemplate>
                <app-contact-banner
                        [type]="'footer'"
                        [contacts]="contactData"
                        [settings]="contactStg"
                ></app-contact-banner>
            </ng-template>
        </div>
        <div *ngIf="country === 'UY'" class="bee-info logo">
            <br/>
            <a href="https://bee-seller.com/" target="blank">
                <img alt="" style="width: 300px"/>
            </a>
            <br/>
            <p>
                <strong>www.bee-seller.com</strong>
            </p>
            <span class="">{{ 'Footer.support' | translate }}</span>
            <!-- </div> -->
        </div>
    </section>
    <img *ngIf="country === 'UY'" src="assets/images/apoyan.png" class="apoyan-img"/>
    <p *ngIf="country === 'UY'" class="copy-right">Copyright © Bee Seller 2023</p>
    <a href="https://www.bee-seller.com/" target="_blank" *ngIf="country === 'MX'" rel="noopener noreferrer">
        <img src="assets/images/footer-mx.png"
             class="apoyan-img"/>
    </a>
    <br/><br/><br/><br/>
</footer>
