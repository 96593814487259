<div class="qty-box">
    <div class="input-group">
        <span class="input-group-prepend">
            <button (click)="decrement()" class="btn quantity-left-minus" data-type="minus" type="button">
                <i class="ti-angle-left"></i>
            </button>
        </span>
        <input [value]="counter" class="form-control input-number" disabled name="quantity" type="text" />
        <span class="input-group-prepend">
            <button (click)="increment()" class="btn quantity-right-plus" data-type="plus" type="button">
                <i class="ti-angle-right"></i>
            </button>
        </span>
    </div>
</div>