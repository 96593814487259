import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ShopComponent} from './shop/shop.component';
import {ElementsComponent} from './elements/elements.component';
import {ElectronicsComponent} from './home/electronics/electronics.component';
import {StoreResolver} from './shared/services/store.resolver';
import {ShopResolver} from './shared/services/shop.resolver';
import {LandingComponent} from './home/landing/landing.component';
import {CategoriesResolver} from "./shared/services/categories.resolver";

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full'
  },
  {
    path: ':storeUri',
    component: ElectronicsComponent,
    resolve: {
      data: StoreResolver,
      data2: CategoriesResolver
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: ':storeUri/shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
    resolve: {
      data: ShopResolver,
      store: StoreResolver
    }
  },
  {
    path: 'elements',
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home/electronics',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
