<div class="container-fluid rounded bg-white mx-0 px-0 shadow-lg">
    <div class="row w-100 justify-content-center banner">
        <div class="col fuente text-center">
            <ng-container *ngIf="'paymentGatewayMsgs.url' | translate as translatedUrl">
                <a *ngIf="translatedUrl !== 'paymentGatewayMsgs.url'" [href]="'paymentGatewayMsgs.url' | translate"
                   target="_blank">
                    <img class="img1" [src]="paymentIconUrl" alt=""
                    /></a>
                <a *ngIf="translatedUrl === 'paymentGatewayMsgs.url'">
                    <img class="img1" [src]="paymentIconUrl" alt=""
                    /></a>
            </ng-container>
            <h5 class="fuenteh">
                <b>{{ 'paymentGatewayMsgs.payment' | translate }}</b>
            </h5>
            <p class="fuentep" style="color: black">
                {{ 'paymentGatewayMsgs.gateway' | translate }}
            </p>
        </div>
        <div class="col text-center">
            <ng-container *ngIf="'currierMsgs.url' | translate as translatedUrl">
                <a *ngIf="translatedUrl !== 'currierMsgs.url'" [href]="translatedUrl"
                   target="_blank"> <img class="img1" [src]="currierIconUrl" alt=""/></a>
                <a *ngIf="translatedUrl === 'currierMsgs.url'"> <img class="img1" [src]="currierIconUrl"
                                                                     alt=""/></a>
            </ng-container>
            <h5 class="fuenteh">
                <b>{{ 'currierMsgs.send' | translate }}</b>
            </h5>
            <p class="fuentep" style="color: black">{{ 'currierMsgs.through' | translate }}.</p>
        </div>
        <div class="col text-center">
            <img class="img1" [src]="requestIconUrl" alt=""/>
            <h5 class="fuenteh">
                <b>{{ 'requestMsgs.receive' | translate }}</b>
            </h5>
            <p class="fuentep" style="color: black">{{ 'requestMsgs.hour' | translate }}</p>
        </div>
    </div>
</div>
