import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-payments-methods',
    templateUrl: './payments-methods.component.html',
    styleUrls: ['./payments-methods.component.scss']
})
export class PaymentsMethodComponent implements OnInit {
    @Input() vi: boolean = false;
    @Input() mc: boolean = false;
    @Input() pay: boolean = false;
    @Input() oca: boolean = false;
    @Input() rp: boolean = false;
    @Input() ld: boolean = false;
    @Input() dn: boolean = false;
    @Input() ab: boolean = false;

    ngOnInit(): void {
    }
}