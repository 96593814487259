import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-pay-delivery-banner',
    templateUrl: './pay-delivery-banner.component.html',
    styleUrls: ['./pay-delivery-banner.component.scss'],
})
export class PayDeliveryBannerComponent implements OnInit {
    @Input() paymentGatewayUrl: string = '';
    @Input() paymentGatewayName: string = '';
    @Input() paymentGatewayMsgs: string[] = ['Pago seguro con ', 'Con tu tarjeta de crédito, débito o en Red Pagos.'];
    @Input() paymentIconUrl: string = '/assets/images/icon/card1.png';

    @Input() currierUrl: string = '';
    @Input() currierName: string = '';
    @Input() currierMsgs: string[] = ['Envíos a todo el país', 'A través de '];
    @Input() currierIconUrl: string = '/assets/images/icon/truck1.png';

    @Input() requestMsgs: string[] = ['Recibe tu pedido', 'En 24 - 72 hs.'];
    @Input() requestIconUrl: string = '/assets/images/icon/CajaAma.png';
    ngOnInit(): void {
        
    }
}