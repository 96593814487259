import { Component, Inject, PLATFORM_ID } from '@angular/core'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { LoadingBarService } from '@ngx-loading-bar/core'
import { delay, map, withLatestFrom } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { getLanguage } from 'src/main'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  )

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private loader: LoadingBarService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.addLangs(['en', getLanguage()])
    }
    this.translate.setDefaultLang(getLanguage())
  }
}
