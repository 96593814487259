import {Component, OnDestroy, OnInit} from '@angular/core'
import {Product} from '../../shared/classes/product'
import {ProductService} from '../../shared/services/product.service'
import {Settings, Store} from '../../shared/classes/store'
import {ActivatedRoute} from '@angular/router'
import {Subscription} from 'rxjs'
import {UserContactSettings} from 'src/app/shared/classes/user-contact'
import {contactBannerFooter, contactBannerHeader} from 'src/app/shared/data/contact-data-config'
import {CategoryService} from 'src/app/shared/services/category.service'
import {NavService} from 'src/app/shared/services/nav.service'

@Component({
    selector: 'app-electronics',
    templateUrl: './electronics.component.html',
    styleUrls: ['./electronics.component.scss'],
})
export class ElectronicsComponent implements OnInit, OnDestroy {
    mobile = false

    public landingProducts: Product[] = []

    public contactHeaderStg: UserContactSettings = contactBannerHeader
    public contactFooterStg: UserContactSettings = contactBannerFooter

    public store: Store
    public storeSettings: Settings

    private subscriptions = new Subscription()

    constructor(
        private activatedRoute: ActivatedRoute,
        public productService: ProductService,
        private categoryService: CategoryService,
        private navService: NavService,
    ) {
        const categories = this.activatedRoute.snapshot.data['data2'];
        this.categoryService.categories = categories.categories;
        this.subscriptions.add(
            this.activatedRoute.data.subscribe((response: { data: Store }) => {
                // Populamos el atributo 'storeUri' con el valor 'uri' que se recibe
                // del get del Store, para poder establecer correctamente el routerLink en la navbar.
                this.store = response.data
                this.storeSettings = response.data.settings

                const cart = sessionStorage.getItem(response.data.uuid)
                if (!cart) {
                    sessionStorage.setItem(response.data.uuid, JSON.stringify([]))
                }
                this.productService.state.cart = JSON.parse(cart)
                localStorage.setItem('storeUri', response.data.uri)
                localStorage.setItem('storeUuid', response.data.uuid)
                localStorage.setItem('storeCountry', response.data.vendorCountry)

                // Si no hay un catalogo, se corta la ejecucion.
                if (!response.data.catalog) {
                    return
                }

                response.data.catalog.skuProducts.forEach((sku: string) => {
                    // Este request se realiza en el listado de productos del inicio,
                    // para esta vista se precisan las imagenes de tamanho medio.
                    if (!sku) {
                        return;
                    }
                    this.subscriptions.add(
                        this.productService.getLandingProduct(sku).subscribe((product: Product) => {
                            this.landingProducts.push(product)
                            this.categoryService.getCategoryByProducts(product)
                            this.navService.leftMenuItems.next(this.categoryService.categoriesByStore)
                        }, error => console.log(error))
                    )
                })
            })
        )
    }

    // HARDCODE ALERT
    ngOnInit(): void {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
            ? true
            : false
    }

    ngOnDestroy(): void {
        // Remove Color
        // document.documentElement.style.removeProperty('--theme-deafult');
        this.subscriptions.unsubscribe()
    }

    onSearch($event: boolean) {
        this.productService.productsFilter().subscribe((products) => {
            this.landingProducts = products.products
        })
    }

    onSortChange($event) {
        this.productService.filter = {
            name: this.productService.filter?.name || '',
            categoryId: this.productService.filter?.categoryId || '',
            priceOrder: $event,
        }
        this.onSearch(true)
    }
}
