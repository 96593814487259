import { Component, Input, OnInit } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import { InitOrderIn } from 'src/app/shared/classes/OrderIn'
import { Payment } from 'src/app/shared/classes/Payment'
import { CheckoutService } from 'src/app/shared/services/checkout.service'
import { getCountry, getKeyMP } from 'src/main'

@Component({
  selector: 'app-payment-method-modal',
  templateUrl: './payment-method-modal.component.html',
  styleUrls: ['./payment-method-modal.component.scss'],
})
export class PaymentMethodModalComponent implements OnInit {
  @Input() order
  private subscriptions = new Subscription()
  public country = getCountry();

  constructor(private checkoutService: CheckoutService, private toast: ToastrService) {}

  ngOnInit() {
    if (getCountry() === 'MX') {
      this.checkoutService.createPaymentMP(this.order.id).subscribe((preferenceId: { preferenceId: string }) => {
        const mp = new MercadoPago(getKeyMP())
        const bricksBuilder = mp.bricks()
        mp.bricks().create('wallet', 'wallet_container', {
          initialization: {
            preferenceId: preferenceId.preferenceId,
          },
          customization:  {
            texts: {
              action: 'pay',
              valueProp: 'none',
            },
            visual: {
              valuePropColor: 'white'
            },
            checkout: {
              theme: {
                elementsColor: "#4287F5",
                headerColor: "#4287F5",
              },
            },
          },
          callbacks: {
            onReady: () => {},
            onSubmit: () => {
              this.checkoutService.order = InitOrderIn()
              sessionStorage.clear()
            },
            onError: () => {
              this.toast.error('No se puede procesar la orden. Intente mas tarde', 'Error del sistema')
            }
          },
        })
      })
    }
  }

  paymentDlocal() {
    this.subscriptions.add(
      this.checkoutService.createPayment(this.order.id).subscribe(
        (payment: Payment) => {
          window.location.href = payment.redirect_url
          this.checkoutService.order = InitOrderIn()
          sessionStorage.clear()
        },
        () => {
          this.toast.error('No se puede procesar la orden. Intente mas tarde', 'Error del sistema')
        }
      )
    )
  }
}
