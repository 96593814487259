<ng-container *ngIf="type === 'email'; else elseTemplate">
  <a *ngIf="value" [ngClass]="linkClass" [href]="href">
    <img [src]="iconUrl" [ngClass]="iconClass"/>
    <p><strong>{{msg | translate}}</strong></p>
  </a>
</ng-container>
<ng-template #elseTemplate>
  <a *ngIf="value" [ngClass]="linkClass" [href]="href" target="_blank">
    <img [src]="iconUrl" [ngClass]="iconClass"/>
    <p><strong>{{msg | translate}}</strong></p>
  </a>
</ng-template>

