import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../services/product.service';
import { Product } from '../../classes/product';
import { Order } from '../../classes/order';
import { CheckoutService } from '../../services/checkout.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search = false;
  @Input() storeUri: string;

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }];

  private subscriptions = new Subscription();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private checkoutService: CheckoutService) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  ngOnInit(): void {
  }

  searchToggle() {
    this.search = !this.search;
  }

  openLeftCart(): void {
    this.productService.OpenCart = true;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  get locationCheckout() {
    return location.pathname.match(/\/checkout/) ? false : true;
  }

  get locationCart() {
    return location.pathname.match(/\/cart/) ? true : false;
  }

  get storeCountry() {
    return localStorage.storeCountry;
  }

  public createOrder() {
    const order: Order = {
      storeUri: localStorage.storeUri, // TODO: Sacar atributo??
      storeUuid: localStorage.storeUuid,
      storeCountry: this.storeCountry, // TODO: Ver que hacer cuando el valor no esta seteado en el LocalStorage.
      orderProducts: [],
      discountCoupon: null,
      delivery: {
        address: '',
        department: '',
        location: '',
        doorNumber: '',
        type: '',
        postalCode: ''
      },
      shopper: {
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
        document: ''
      },
    };

    this.products.forEach(product =>
      order.orderProducts.push({
        sku: product.sku,
        pricePesos: this.productService.getEarningsValue(product.pricePesos),
        quantity: product.quantity,
      })
    );

    this.subscriptions.add(this.checkoutService.createOrder(order));
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

}
