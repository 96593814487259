import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Order } from '../classes/order';
import { Coupon } from '../classes/coupon';
import { InitOrderIn, OrderIn } from '../classes/OrderIn';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  private readonly orderBaseUrl: string;
  private readonly preferenceBaseUrl: string;
  private readonly notificationBaseUrl: string;
  private readonly mercadoPagoUrl: string;
  private readonly couponBaseUrl: string;
  private orderSubject: BehaviorSubject<OrderIn> = new BehaviorSubject<OrderIn>({ ...InitOrderIn() });
  order$: Observable<OrderIn> = this.orderSubject.asObservable();

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private porductService: ProductService,
    private toastrSertvice: ToastrService,
    private router: Router
  ) {
    this.orderBaseUrl = `${baseUrl}/orders`;
    this.preferenceBaseUrl = `${baseUrl}/payments`;
    this.notificationBaseUrl = `${baseUrl}/notifications`;
    this.couponBaseUrl = `${baseUrl}/coupon`;
    this.mercadoPagoUrl = `${baseUrl}/payments/mercadopago`;
  }

  get orderObservable(): any {
    return this.order$;
  }

  set order(orderIn: OrderIn) {
    this.orderSubject.next(orderIn);
  }

  getOrder(orderId: number): Observable<any> {
    return this.http.get(`${this.orderBaseUrl}/anon/orders/${orderId}`);
  }

  createOrder(orderToCreate: Order) {
    return this.http.post(this.orderBaseUrl, orderToCreate).subscribe((orderCreated: OrderIn) => {
      this.order = orderCreated;
      this.router.navigate([`/${orderToCreate.storeUri}/shop/checkout`])
    }, error => {
      if (error.status === 400) {
        if (error.error.noStockProductNames) {
          this.toastrSertvice.error(
            `${error.error.noStockProductNames.join(
              '\n  - '
            )}`
            , `El/los siguientes productos no tiene stock`);
        }
      }
    });
  }

  updateDelivery(deliveryData: any): Observable<any> {
    return this.http.post(`${this.orderBaseUrl}/${deliveryData.id}/delivery`, { delivery: { ...deliveryData.delivery }, shopper: { ...deliveryData.shopper } });
  }

  appliCoupon(orderId: number, code: string) {
    return this.http.post(`${this.orderBaseUrl}/${orderId}/coupon/${code}`, null)
  }

  resetCoupon(orderId: number, code: string) {
    return this.http.post(`${this.orderBaseUrl}/${orderId}/coupon/${code}/reset`, null)
  }

  createPayment(order_id: number) {
    return this.http.post(this.preferenceBaseUrl, {
      order_id
    });
  }

  createPaymentMP(order_id: number) {
    return this.http.post(this.mercadoPagoUrl, {
      order_id
    });
  }

  validateCoupon(coupon: string, store: string): Observable<Coupon> {
    return this.http.get<Coupon>(`${this.couponBaseUrl}/${coupon}/validate?store=${store}`);
  }

  cancelOrder(orderId: number) {
    return this.http.post(`${this.orderBaseUrl}/${orderId}/cancel`, {})
  }
}
