import { Coupon } from "./coupon";

export interface OrderIn {
    id: number;
    coin: string;
    createdAt: string;
    deliveryType: string;
    deliveryShipCost: number;
    profitBs: number;
    profitSeller: number;
    profitSellerPercentage: number;
    status: string;
    subtotal: number;
    totalPrice: number;
    discountCoupon: Coupon;
    products: ProductsOrderIn[];
    storeUri: string; // TODO: Sacar atributo??
    storeUuid: string;
    storeCountry: string;
    deliveryModelList: DeliveryModelList[];
}

export interface DeliveryModelList  {
    type: string;
    providerCount: number;
    shipCost: number;
}


export interface ProductsOrderIn {
    artNombreMl: string;
    price: number;
    quantity: number;
    sku: string;
}

export const InitOrderIn = (): OrderIn => {
    return {
        id: null,
        coin: '',
        createdAt: '',
        deliveryType: '',
        deliveryShipCost: 0,
        profitBs: 0,
        profitSeller: 0,
        profitSellerPercentage: 0,
        status: '',
        subtotal: 0,
        totalPrice: 0,
        discountCoupon: {
            amount: 0,
            code: '',
            discountPercentage: 0,
            percentage: 0,
            subTotalPercentage: 0,
            valid: false
        },
        products: [],
        storeUri: '',
        storeUuid: '',
        storeCountry: '',
        deliveryModelList: []
    }
}
