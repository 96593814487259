import { Component, Input, OnInit } from "@angular/core";
import { UserContact, UserContactSettings } from "../../classes/user-contact";
import { Contacts } from "../../classes/store";

@Component({
    selector: 'app-contact-banner',
    templateUrl: './contact-banner.component.html',
    styleUrls: ['./contact-banner.component.scss'],
})
export class ContactBannerComponent implements OnInit {
    @Input() type: 'header' | 'footer' | 'all';
    @Input() contacts: Contacts = {} as Contacts;
    @Input() settings: UserContactSettings;
    @Input() country: string;
    public codeArea: string = '';
    constructor() {}

    ngOnInit(): void {
        this.validPhone(this.country, this.contacts.telephone);
    }

    validPhone(country: string, number: string) {
        if (country === 'UY') {
            this.codeArea = '598';
        }
        if(number.length > 0) { //TODO: mejorar validacion de telefono
            this.contacts.telephone = this.codeArea + number
        }
    }
}