import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../classes/product';
import { CheckoutService } from 'src/app/shared/services/checkout.service';
import { Order } from 'src/app/shared/classes/order';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

  @Input() direction = 'right'; // Default Direction Right
  @Input() storeUri: string;

  public products: Product[] = [];

  private subscriptions = new Subscription();

  constructor(
    public productService: ProductService,
    private checkoutService: CheckoutService,
    private router: Router,
  ) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  get storeCountry(): string {
    return localStorage.storeCountry.toUpperCase();
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  get locationCheckout() {
    return location.pathname.match(/\/checkout/) ? false : true;
  }

  public createOrder() {
    const order: Order = {
      storeUri: localStorage.storeUri, // TODO: Sacar atributo??
      storeUuid: localStorage.storeUuid,
      storeCountry: this.storeCountry, // TODO: Ver que hacer cuando el valor no esta seteado en el LocalStorage.
      orderProducts: [],
      discountCoupon: null,
      delivery: {
        address: '',
        department: '',
        location: '',
        doorNumber: '',
        type: '',
        postalCode: ''
      },
      shopper: {
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
        document: ''
      },
    };

    this.products.forEach(product =>
      order.orderProducts.push({
        sku: product.sku,
        pricePesos: this.productService.getEarningsValue(product.pricePesos),
        quantity: product.quantity,
      })
    );

    this.subscriptions.add(this.checkoutService.createOrder(order));
    this.closeCart()
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }
}
