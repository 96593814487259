<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-cart">
      <div class="cart">
        <a (click)="openLeftCart()" href="javascript:void(0)" >
          <img alt="" class="img-fluid" src="assets/images/CarritoSinFondo.png" style="max-height: 24px">
          <i class="ti-shopping-cart" size="20"></i>
        </a>
      </div>
      <span class="cart_qty_cls" [style]="{'background': '#ffffff', 'border': 'none'}">{{ products?.length }}</span>
  </ul>
</div>
