<app-header
  [sticky]="true"
  [storeUri]="store.uri"
  [themeLogo]="storeSettings.logoUrl"
  [storeColor]="storeSettings.headerColor"
  [storeName]="storeSettings.storeName"
  [storeNameColor]="storeSettings.storeNameColor"
  (onSearch)="onSearch($event)"
></app-header>
<br /><br /><br />
<app-store-banner
  [bannerTitleColor]=""
  [bannerImg]="storeSettings.bannerImg"
  [bannerTitle]=""
  [bannerSubtitle]=""
>
</app-store-banner>
<section
  class="section-b-space section-t-space"
  [style]="{ background: storeSettings.backgroundColor }"
>
  <app-contact-banner
    [type]="'header'"
    [contacts]="storeSettings.contacts"
    [country]="store.vendorCountry"
    [settings]="contactHeaderStg"
  ></app-contact-banner>

  <br />
  <app-pay-delivery-banner
    [paymentGatewayUrl]="store.vendorCountry === 'UY' ? 'https://dlocal.com' : ''"
    paymentGatewayName="d-Local"
    currierName="UES"
    [currierUrl]="store.vendorCountry === 'UY' ? 'https://www.ues.com.uy/rastreo_paquete.html' : ''"
  ></app-pay-delivery-banner>
  <br />
  <br />
  <div class="container">
    <div class="dropdown">
      <ng-select
        [clearable]="false"
        placeholder="Ordenar por..."
        [searchable]="false"
        class="custom border rounded electronic"
        (change)="onSortChange($event)"
      >
        <ng-option value="NO">Relevantes</ng-option>
        <ng-option value="ASC">Precio menor a mayor</ng-option>
        <ng-option value="DESC">Precio mayor a menor</ng-option>
      </ng-select>
    </div>
    <div class="row">
      <div class="col">
        <div class="no-slider row" *ngIf="landingProducts.length === 0">
          <span style="font-size: x-large; margin-inline: auto">
            {{ 'Home.empty-catalog' | translate }}
          </span>
        </div>
        <div class="grid" *ngIf="landingProducts.length > 0">
          <ng-container *ngFor="let product of landingProducts">
            <app-product-box-one
              [product]="product"
              [currency]="productService?.Currency"
              [storeUri]="store.uri"
              [btnColor]="storeSettings.backgroundColor"
            >
            </app-product-box-one>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Cart Open To bottom Side-->
<app-cart-variation [storeUri]="store.uri"></app-cart-variation>
<app-footer [contactData]="storeSettings.contacts" [contactStg]="contactFooterStg"></app-footer>
<app-tap-to-top [color]="storeSettings.backgroundColor"></app-tap-to-top>
