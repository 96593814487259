import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '../classes/store';
import {StoreService} from './store.service';

@Injectable({
  providedIn: 'root'
})
export class StoreResolver implements Resolve<Store> {

  constructor(private router: Router, private storeService: StoreService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Store> {
    try {
      const store: Store = await this.storeService.getLandingStore(route.params.storeUri).toPromise();
      this.storeService.setStore(store);

      if (store.catalog) {
        this.storeService.setPercentage(store.catalog.percentage);
      }

      return store;

    } catch (e) {
      if (e.status === 404) {
        await this.router.navigateByUrl('/pages/404');

      } else if (e.status === 423) { // Status 423 = Locked
        // TODO: mostrar web de pagina en mantenimiento o algo asi
      }
    }
  }
}
